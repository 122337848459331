import React from "react";
import { Switch, Route } from "react-router-dom";
import AuthScreen from "./screens/AuthScreen";
import FoodMenuScreen from "./screens/FoodMenuScreen";
import ItemDetailsScreen from "./screens/ItemDetailsScreen";
import CheckOutScreen from "./screens/CheckOutScreen";
import EditOrderItemScreen from "./screens/EditOrderItemScreen";
import OrderSuccessScreen from "./screens/OrderSuccessScreen";
import EReceiptScreen from "./screens/EReceiptScreen";

const MenuStack = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={FoodMenuScreen} />
      <Route path={`${match.path}/item`} component={ItemDetailsScreen} />
    </Switch>
  );
};

const App = ({ match }) => {
  return (
    <Switch>
      <Route exact={true} path="/" component={AuthScreen} />
      <Route path="/menu/:outletId" component={MenuStack} />
      <Route path="/checkout" component={CheckOutScreen} />
      <Route path="/editOrderItem" component={EditOrderItemScreen} />
      <Route path="/orderSuccess" component={OrderSuccessScreen} />
      <Route path="/ereceipt" component={EReceiptScreen} />
    </Switch>
  );
};

export default App;
