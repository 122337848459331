import React, { Component } from "react";
import { View, TouchableOpacity, Text } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import { withTheme } from "react-native-paper";

class GradientButton extends Component {
  render() {
    let {
      onPress,
      style,
      content,
      loading,
      disabled,
      uppercase,
      theme,
      startColor,
      endColor
    } = this.props;
    if (uppercase || true) {
      content = content.toUpperCase();
    }
    return (
      <View style={style}>
        <TouchableOpacity
          onPress={onPress}
          style={{ borderRadius: 3 }}
          disabled={disabled || loading}
        >
          <View>
            <LinearGradient
              start={{ x: 0, y: 0 }}
              end={{ x: 0, y: 1 }}
              locations={[0, 0.5]}
              colors={[
                startColor || theme.colors.buttonLinearStart,
                endColor || theme.colors.buttonLinearEnd
              ]}
              style={{ padding: 15, alignItems: "center", borderRadius: 3 }}
            >
              <View>
                <Text
                  style={{
                    color: theme.colors.titleFilled,
                    fontSize: 15
                  }}
                >
                  {content}
                </Text>
              </View>
            </LinearGradient>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default withTheme(GradientButton);
