import React, { useState } from "react";
import { StyleSheet, Image, View } from "react-native";
import { Card, Title, TouchableRipple } from "react-native-paper";
import { ReactComponent as Wave } from "../../assets/wave1.svg";

const GiftCardItem = (props) => {
  console.warn(props.image);
  return (
    <Card style={[styles.cardPadding, props.style]}>
      <TouchableRipple onPress={props.onPress}>
        <View style={styles.card}>
          <View>
            <Image
              resizeMode="contain"
              style={styles.image}
              source={{
                uri: props.image,
              }}
            />
            {/* <View style={styles.wave}>
              <Wave />
            </View> */}
          </View>
          <View style={{ flex: 1 }}>
            <Title style={styles.title}>{props.title}</Title>
          </View>
        </View>
      </TouchableRipple>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    color: "#47B3B0",
    fontWeight: "bold",
    textAlign: "center",
  },
  cardPadding: {
    flexDirection: "row",
    borderRadius: 8,
    elevation: 5,
  },
  disabledGiftcard: {
    width: "100%",
    height: "100%",
    backgroundColor: "#000000",
    opacity: 0.5,
    position: "absolute",
    borderRadius: 10,
  },
  image: {
    width: 100,
    height: 100,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  wave: {
    position: "absolute",
    right: 5,
    height: 100,
    width: 23,
  },
  giftcardOption: {
    position: "absolute",
    right: "25%",
    width: "25%",
  },
});

export default GiftCardItem;
