import React, { Component } from "react";
import {
  Text,
  TextInput,
  Avatar,
  TouchableRipple,
  Appbar,
  Divider,
} from "react-native-paper";
import {
  SafeAreaView,
  View,
  StyleSheet,
  ScrollView,
  Dimensions,
} from "react-native";
import { titleCase } from "../utils/helper";
import AsyncStorage from "@callstack/async-storage";
import GButton from "../components/GradientButton";
import { client } from "../utils/apiClient";
import RadioButtons from "../components/RadioButtons";

export default class ItemDetailsScreen extends Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      instructions: "",
      count: 1,
      totalPrice: 0,
      totalAddPrice: 0,
      details: null,
      disablebutton: false,
      itemId: 0,
    };
    this.calculateTotal = this.calculateTotal.bind(this);
    this.setType = this.setType.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  increment() {
    this.setState(
      {
        count: this.state.count + 1,
      },
      () => this.calculateTotal()
    );
  }

  decrement() {
    if (this.state.count > 1) {
      this.setState(
        {
          count: this.state.count - 1,
        },
        () => this.calculateTotal()
      );
    }
  }

  async componentDidMount() {
    const { itemId } = this.props.location.state;
    this.setState({ itemId });

    try {
      const res = await client.get(`/menuItem2/${itemId}`);
      let newState = { details: res.data };

      if (newState.details.itemAddOns) {
        for (const addOn of newState.details.itemAddOns) {
          newState[addOn.name] = addOn.itemAddOnChoices[0].id;
        }
      }
      this.setState(newState);
      this.calculateTotal();
    } catch (err) {
      this.goBack();
    }
  }

  async addToCart() {
    let cart = await AsyncStorage.getItem("cart");
    const tableId = await AsyncStorage.getItem("tableId");
    const outletId = await AsyncStorage.getItem("outletId");
    let options = [];

    if (this.state.details.itemAddOns) {
      this.state.details.itemAddOns.map((v, i, a) => {
        v.itemAddOnChoices.map((itemAddOnChoice, i, a) => {
          if (this.state[v.name] === itemAddOnChoice.id) {
            options.push({
              id: this.state[v.name],
              option: v.name,
              choice: itemAddOnChoice.name,
            });
          }
          return null;
        });
        return null;
      });
    }

    if (!cart) {
      let data = {
        tableId: tableId,
        outletId: outletId,
        items: [],
      };
      cart = data;
    } else {
      cart = JSON.parse(cart);
    }

    let item = {
      id: cart.items.length,
      itemId: this.state.itemId,
      title: this.state.details.name,
      options: options,
      quantity: this.state.count,
      totalPrice: this.state.totalPrice,
      remark: this.state.instructions,
    };

    cart.items.push(item);
    await AsyncStorage.setItem("cart", JSON.stringify(cart));
    this.goBack();
  }

  goBack() {
    this.props.history.goBack();
  }

  calculateTotal() {
    let basePrice = parseFloat(this.state.details.price);
    let quantity = this.state.count;
    let totaladdOnPrice = 0;
    let totalPrice = 0;
    let addOnPrice = [];
    if (this.state.details.itemAddOns) {
      this.state.details.itemAddOns.map((v, i, a) => {
        v.itemAddOnChoices.map((itemAddOnChoice, i, a) => {
          if (itemAddOnChoice.id === this.state[v.name]) {
            addOnPrice.push(parseFloat(itemAddOnChoice.price));
          }
          return null;
        });
        return null;
      });
    }

    addOnPrice.forEach((price) => {
      totaladdOnPrice += price;
    });

    totalPrice =
      (parseFloat(totaladdOnPrice) + parseFloat(basePrice)) *
      parseInt(quantity);

    this.setState({ totalPrice: totalPrice.toFixed(2) });
  }

  setType(choiceName, choice) {
    this.setState({ [choiceName]: choice }, () => this.calculateTotal());
  }

  render() {
    let height = Dimensions.get("window").height * 0.05;
    let beverageWidth = Dimensions.get("window").width * 0.6;
    const windowHeight = Dimensions.get("window").height;
    return (
      <SafeAreaView style={{ flex: 1, height: windowHeight }}>
        <Appbar.Header style={{ backgroundColor: "#FFFFFF", elevation: 0 }}>
          <Appbar.BackAction onPress={this.goBack} />
          <Appbar.Content
            color="#FFFFFF"
            style={{ alignItems: "center" }}
            titleStyle={styles.AppBar}
          />
          <Appbar.Action />
        </Appbar.Header>

        <View style={styles.topView}>
          <Text
            style={{
              width: beverageWidth,
              fontSize: 18,
              fontWeight: "bold",
              color: "#636466",
              textTransform: "uppercase",
            }}
          >
            {this.state.details ? this.state.details.name : ""}
          </Text>
          <View>
            <Text
              style={{ fontSize: 18, fontWeight: "bold", color: "#636466" }}
            >
              {this.state.details ? this.state.details.price : ""}
            </Text>
            <Text style={{ fontSize: 7, color: "#D8E0E1" }}>Base Price</Text>
          </View>
        </View>

        <ScrollView contentContainerStyle={{ flexGrow: 1, paddingBottom: 66 }}>
          <View
            style={{
              width: "100%",
              height: height / 2,
              backgroundColor: "#f4f3e9",
            }}
          />
          {this.state.details && this.state.details.itemAddOns ? (
            this.state.details.itemAddOns.map((item, key) => (
              <View key={key} style={{ marginLeft: 20 }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={styles.itemTitle}>CHOOSE YOUR {item.name}</Text>
                  <Text
                    style={{
                      color: "#636466",
                      fontSize: 9,
                      marginLeft: 10,
                      marginTop: 29,
                    }}
                  />
                </View>
                <RadioButtons
                  style={{ marginLeft: 10, marginRight: 10 }}
                  onValueChange={(value) => {
                    this.setType(item.name, value);
                  }}
                  value={this.state[item.name]}
                  options={item.itemAddOnChoices.map((type, k) => {
                    const text = `${titleCase(
                      type.name.toLowerCase()
                    )} (+${parseFloat(type.price).toFixed(2)})`;
                    return { key: type.id, text };
                  })}
                />
                <Divider
                  style={{
                    left: -20,
                    marginTop: 20,
                    width: "110%",
                  }}
                />
              </View>
            ))
          ) : (
            <View />
          )}

          <View style={styles.bottomView}>
            <View style={styles.countView}>
              <TouchableRipple onPress={this.decrement.bind(this)}>
                <Avatar.Icon
                  color="#63a8a2"
                  icon="minus"
                  size={30}
                  style={[styles.counterIcon, { borderRightWidth: 1 }]}
                />
              </TouchableRipple>
              <Text style={styles.countText}>{this.state.count}</Text>
              <TouchableRipple onPress={this.increment.bind(this)}>
                <Avatar.Icon
                  color="#63a8a2"
                  icon="plus"
                  size={30}
                  style={[styles.counterIcon, { borderLeftWidth: 1 }]}
                />
              </TouchableRipple>
            </View>
            <Text
              style={{
                color: "#636466",
                marginLeft: 20,
                marginBottom: 30,
                fontWeight: "bold",
              }}
            >
              Special Instructions
            </Text>
            <TextInput
              style={styles.textField}
              placeholder="E.g. No onions, please"
              value={this.state.instructions}
              onChangeText={(instructions) => {
                this.setState({ instructions: instructions });
              }}
            />
          </View>
        </ScrollView>
        <GButton
          onPress={() => this.addToCart()}
          style={{ width: "100%", position: "absolute", bottom: 0 }}
          content={"Add to Cart - RM" + this.state.totalPrice}
        />
        {/* <Button
          mode="outlined"
          style={styles.button}
          onPress={() => this.addToCart()}>
          Add to Cart - RM{this.state.totalPrice}
        </Button> */}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  AppBar: {
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: 18,
  },
  button: {
    width: "100%",
    borderColor: "#2A8597",
    borderWidth: 2,
  },
  back: {
    position: "relative",
    top: 0,
    left: 0,
    // marginTop: 5,
    marginLeft: 10,
  },
  topView: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 25,
    position: "relative",
  },
  // headerWrapper:
  // {
  //   height: 50,
  // },
  title: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#65B1AF",
    marginTop: 20,
  },
  textField: {
    height: 40,
    width: "90%",
    backgroundColor: "#F1F2F2",
    alignSelf: "center",
    marginBottom: 50,
  },
  bottomView: {
    borderWidth: 1,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderColor: "#D8E0E1",
    backgroundColor: "#FFF",
  },
  countText: {
    fontWeight: "bold",
    fontSize: 20,
    color: "#636466",
    marginRight: 17,
    marginLeft: 17,
  },
  counterIcon: {
    backgroundColor: "white",
    borderColor: "#D8E0E1",
    borderRadius: 5,
  },
  countView: {
    marginTop: 30,
    marginBottom: 30,
    flexDirection: "row",
    alignSelf: "center",
    borderWidth: 1,
    borderColor: "#D8E0E1",
    borderRadius: 5,
  },
  radio: {
    flexDirection: "row",
    alignItems: "center",
  },
  typeText: {
    color: "#636466",
    marginLeft: 10,
    textTransform: "capitalize",
  },
  itemTitle: {
    marginBottom: 15,
    marginLeft: 10,
    marginTop: 20,
    fontWeight: "bold",
    fontSize: 15,
    color: "#636466",
  },
});
