import React, { Component } from "react";
import { Text, Appbar, Portal } from "react-native-paper";
import {
  SafeAreaView,
  View,
  StyleSheet,
  Image,
  ScrollView,
  Dimensions,
  BackHandler,
} from "react-native";
import AsyncStorage from "@callstack/async-storage";
import GButton from "../components/GradientButton";
import EGiftModal from "../components/cta/EGift";
import { client } from "../utils/apiClient";

export default class OrderSuccessScreen extends Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      totalAmount: 0,
      paymentMethod: "cash",
      outletId: 0,
      orderId: 0,
      paid: false,
      showCTA: true,
      ctaInfo: null,
    };
  }

  _checkReceipt = async () => {
    this.props.history.push({
      pathname: "ereceipt",
      state: {
        orderId: this.state.orderId,
      },
    });
  };

  async checkWelcomeGift() {
    const res = await AsyncStorage.multiGet(["email", "outletInfo"]);
    const email = res[0][1] || "";
    const outletInfo = JSON.parse(res[1][1]);
    if (email.length > 0 && outletInfo) {
      const apiRes = await client.post("/refer", {
        email,
        merchantId: outletInfo.merchantId,
        outletId: parseInt(this.state.outletId),
      });
      if (apiRes.data) {
        const giftCardRes = await client.get(
          `/giftCard/${apiRes.data.giftCardId}`
        );

        if (giftCardRes.data) {
          this.setState({
            ctaInfo: {
              logo: giftCardRes.data.merchant.logo,
              title: giftCardRes.data.title,
            },
          });
        }
      }
    }
  }

  async componentDidMount() {
    BackHandler.addEventListener("hardwareBackPress", this.handleBackPress);
    if (
      this.props.location.state &&
      typeof this.props.location.state === "object"
    ) {
      this.setState({
        totalAmount: this.props.location.state.totalPrice,
        paymentMethod: this.props.location.state.paymentMethod,
        orderId: this.props.location.state.orderId,
        paid: this.props.location.state.paid,
      });
      AsyncStorage.getItem("outletId").then((outletId) => {
        this.setState({ outletId });
        this.checkWelcomeGift();
      });
    }
    AsyncStorage.removeItem("cart");
  }

  componentWillUnmount() {
    BackHandler.removeEventListener("hardwareBackPress", this.handleBackPress);
  }

  handleBackPress = async () => {
    if (!this.props.navigation.isFocused()) {
      // The screen is not focused, so don't do anything
      return false;
    }
    let type = await AsyncStorage.getItem("orderType");
    if (type === "self-order") {
      this.props.navigation.navigate("Actions");
    } else {
      this.props.navigation.navigate("MerchantDetail");
    }
    return true;
  };

  render() {
    let logoWidth = Dimensions.get("window").width * 0.44;
    let logoHeight = Dimensions.get("window").height * 0.34;
    let windowHeight = Dimensions.get("window").height;
    const paidText = this.state.paid
      ? "Your payment is successful, your order has been submitted."
      : "Your order has been submitted.";
    return (
      <SafeAreaView style={{ flex: 1, height: windowHeight }}>
        <Appbar.Header style={{ backgroundColor: "#218096", elevation: 0 }}>
          <Appbar.Action />
          <Appbar.Content
            color="#FFFFFF"
            style={{ alignItems: "center" }}
            titleStyle={styles.AppBar}
            title="ORDER SUCCESSFUL"
          />
          <Appbar.Action />
        </Appbar.Header>

        <ScrollView
          contentContainerStyle={{ flexGrow: 1, alignItems: "center" }}
        >
          <Image
            source={require("../assets/paymentSuccessful.png")}
            resizeMode="contain"
            style={{
              height: logoHeight,
              width: logoWidth,
              marginTop: logoHeight / 3,
              marginLeft: 20,
            }}
          />
          <Text style={styles.title}>Order Successful</Text>
          <View style={{ width: "55%", marginTop: 30 }}>
            <Text style={{ textAlign: "center" }}>{paidText}</Text>
          </View>
        </ScrollView>
        {this.state.orderId > 0 && (
          <GButton
            onPress={() => this._checkReceipt()}
            style={{ width: "100%", position: "absolute", bottom: 0 }}
            content={"E-Receipt"}
          />
        )}
        {/* <Button
          style={styles.button}
          onPress={() => this.props.navigation.navigate('EReceipt')}>
          View Receipt
          </Button> */}
        <Portal>
          {this.state.showCTA && this.state.ctaInfo && (
            <EGiftModal ctaInfo={this.state.ctaInfo} />
          )}
        </Portal>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  AppBar: {
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: 18,
  },
  button: {
    width: "100%",
    borderColor: "#2A8597",
    borderWidth: 2,
  },
  header: {
    position: "absolute",
    marginTop: 20,
    fontSize: 18,
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  headerWrapper: {
    alignItems: "center",
    backgroundColor: "#218096",
    height: "10%",
  },
  title: {
    fontWeight: "bold",
    fontSize: 19,
    color: "#65B1AF",
    marginTop: 20,
  },
  close: {
    position: "absolute",
    bottom: 0,
    right: 5,
    marginTop: 15,
    marginRight: 5,
  },
});
