import React, { Fragment } from "react";
import { Text } from "react-native";
import AsyncStorage from "@callstack/async-storage";
import { client } from "../utils/apiClient";

class Auth extends React.Component {
  state = {
    ready: false,
  };

  async checkToken(channelToken, outletId) {
    // compare with existing token
    const prevChannelToken = await AsyncStorage.getItem("ctoken");
    var token = AsyncStorage.getItem("token");

    // User name and email passed from entrance form using query
    const userData = [];
    const parsedUrl = new URL(window.location.href);
    for (const k of ["name", "email"]) {
      if (parsedUrl.searchParams.get(k)) {
        userData.push([k, parsedUrl.searchParams.get(k)]);
      }
    }
    if (userData.length > 0) {
      await AsyncStorage.multiSet(userData);
    }

    await AsyncStorage.multiRemove(["ctoken", "token"]);
    // Exchange for token here
    try {
      const res = await client.get(`/token?ctoken=${channelToken}`);
      const token = res.data.token;

      if (token) {
        await AsyncStorage.multiSet([
          ["token", token],
          ["ctoken", channelToken],
        ]);
        this.props.history.push(`/menu/${outletId}`);
        return;
      }
    } catch (err) { }

    this.setState({ ready: true });
  }

  async componentDidMount() {
    const parsedUrl = new URL(window.location.href);
    try {
      var outletId = '';
      var tableId = '';
      var type = 'entrance';
      const showDownload = parsedUrl.searchParams.get("showDownload");
      if (showDownload) {
        AsyncStorage.setItem("showDownload", showDownload);
      }
      const channelToken = parsedUrl.searchParams.get("ctoken") || 'TABLE:GUEST';
      if (parsedUrl.searchParams.get("o")) {
        const outletRes = await client.get(`/outlet/shortcode/${parsedUrl.searchParams.get("o")}`)
        outletId = outletRes.data.id;
        tableId = "1";
      }
      else {
        const payload = JSON.parse(atob(parsedUrl.searchParams.get("payload")));
        outletId = payload.outlet;
        tableId = payload.uid;
        type = payload.type;
        if (payload.type !== "table" && payload.type !== "entrance") {
          throw Error("Invalid QR");
        }
      }

      AsyncStorage.multiRemove(["outletInfo", "cart", "tableId"]).then(() => {
        AsyncStorage.multiSet([
          ["outletId", outletId],
          ["tableId", tableId],
          ["orderType", "self-order"],
          ["qrType", type],
        ]).then(() => {
          if (channelToken && outletId && tableId) {
            this.checkToken(channelToken, outletId);
          }
        });
      });
    } catch (err) {
      AsyncStorage.clear();
      this.setState({ ready: true });
    }
  }

  render() {
    return (
      <Fragment>
        {this.state.ready && <Text>Unauthorized access</Text>}
      </Fragment>
    );
  }
}

export default Auth;
