import React, { Component, Fragment } from "react";
import {
  Text,
  Button,
  TextInput,
  Switch,
  Avatar,
  TouchableRipple,
  Appbar,
  Divider,
  IconButton,
} from "react-native-paper";
import {
  SafeAreaView,
  View,
  StyleSheet,
  Image,
  ScrollView,
  Dimensions,
} from "react-native";
import Moment from "react-moment";
import { ReactComponent as Wallet } from "../assets/wallet.svg";
import { client } from "../utils/apiClient";
import GButton from "../components/GradientButton";
import AsyncStorage from "@callstack/async-storage";

export default class OrderHistory extends Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      orderId: props.location.state ? props.location.state.orderId : null,
      date: "",
      time: "",
      cost: "",
      subtotal: "",
      taxes: "",
      total: "",
      discount: "",
      status: "",
      orderItems: [],
      statusColor: "#ffffff",
      collectionTime: "",
      paymentMethod: "",
      outletId: 0,
      paymentMethods: [
        { label: "Cash", value: "cash" },
        { label: "Credit card", value: "cc" },
      ],
      outletInfo: null,
      orderTime: "",
    };
    AsyncStorage.multiGet(["outletId", "outletInfo"]).then((res) => {
      this.setState({ outletId: res[0][1], outletInfo: JSON.parse(res[1][1]) });
    });
  }

  translateStatus(status) {
    let ret = { statusStr: "Pending", statusColor: "#f8a717" };
    switch (status) {
      case 1:
        ret = { statusStr: "Accepted", statusColor: "#2996A3" };
        break;
      case 2:
        ret = { statusStr: "Completed", statusColor: "#00FF00" };
        break;
      case 3:
        ret = { statusStr: "Cancelled", statusColor: "#444" };
        break;
    }
    return ret;
  }

  async componentDidMount() {
    if (!this.state.orderId) {
      return;
    }
    client
      .get(`/order2/${this.state.orderId}`)
      .then((res) => {
        if (!res.data) {
          throw Error(res.statusText);
        }
        const resJ = res.data;
        const { statusStr, statusColor } = this.translateStatus(resJ.status);
        this.setState({
          subtotal: resJ.subtotal,
          total: resJ.total,
          taxes: resJ.tax,
          discount: resJ.discount,
          orderItems: resJ.orderItems,
          collectionTime: resJ.collectionTime,
          status: statusStr,
          statusColor: statusColor,
          paymentMethod: resJ.paymentMethod,
          orderTime: resJ.createdAt,
        });
      })
      .catch((err) => {
        console.warn(err);
      });
  }

  translatePaymentMethod(rawString) {
    let method = this.state.paymentMethods.find((o) => o.value === rawString);
    return method ? method.label : "";
  }

  render() {
    let logoSize = Math.min(Dimensions.get("window").width * 0.2, 80);
    let quantityWidth = Dimensions.get("window").width * 0.1;
    let titleWidth = Dimensions.get("window").width * 0.5;
    let priceWidth = Dimensions.get("window").width * 0.3;
    const windowHeight = Dimensions.get("window").height;
    const dateToFormat = this.state.collectionTime;
    const paymentMethod = this.translatePaymentMethod(this.state.paymentMethod);
    const mercantName = this.state.outletInfo ? this.state.outletInfo.name : "";
    return (
      <SafeAreaView style={{ flex: 1, height: windowHeight }}>
        <Appbar.Header style={{ backgroundColor: "#218096", elevation: 0 }}>
          <Appbar.Action />
          <Appbar.Content
            color="#FFFFFF"
            style={{ alignItems: "center" }}
            titleStyle={styles.AppBar}
            title={"E-RECEIPT"}
          />
          <Appbar.Action />
        </Appbar.Header>

        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1 }}>
          <View
            style={{
              alignItems: "center",
              height: 50,
              backgroundColor: "#218096",
            }}
          >
            <View style={[styles.logo, { bottom: -(logoSize / 2) }]}>
              {this.state.outletInfo && (
                <Avatar.Image
                  size={logoSize}
                  source={{
                    uri: this.state.outletInfo.logo,
                  }}
                />
              )}
            </View>
          </View>
          <View style={{ height: logoSize / 2 + 20 }} />
          <View style={styles.container2}>
            <View
              style={{
                alignItems: "center",
                marginBottom: 0,
                position: "absolute",
                top: -40,
              }}
            />
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 17,
                color: "#2A8597",
              }}
            >
              RM {this.state.total}
            </Text>

            <Text
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 16,
                color: "#2A8597",
                marginTop: 20,
              }}
            >
              {mercantName}
            </Text>

            <View
              style={{
                marginTop: 20,
                marginBottom: 80,
                flexDirection: "row",
              }}
            >
              <Text style={{ marginRight: 10, color: this.state.statusColor }}>
                {this.state.status}
              </Text>
              {this.state.orderTime.length > 0 && (
                <Moment element={Text} format="YYYY/MM/DD">
                  {this.state.orderTime}
                </Moment>
              )}
              <Text style={{ marginRight: 5, marginLeft: 5 }}>at</Text>
              {this.state.orderTime.length > 0 && (
                <Moment element={Text} format="HH:mm">
                  {this.state.orderTime}
                </Moment>
              )}
            </View>
          </View>

          {this.state.collectionTime ? (
            <View
              style={{
                marginTop: 20,
                marginBottom: 80,
                marginLeft: 95,
                flexDirection: "row",
              }}
            >
              <Text>Collection Time: </Text>
              <View style={{ flexDirection: "row" }}>
                <Moment element={Text} format="YYYY/MM/DD">
                  {dateToFormat}
                </Moment>
                <Text style={{ marginRight: 5, marginLeft: 5 }}>at</Text>
                <Moment element={Text} format="HH:mm">
                  {dateToFormat}
                </Moment>
              </View>
            </View>
          ) : (
            <View />
          )}

          {/* bottomView */}
          <View style={styles.bottomView}>
            <Text
              style={{
                color: "#2A8597",
                marginLeft: 20,
                marginTop: 25,
                fontWeight: "bold",
              }}
            >
              Order Summary
            </Text>

            {this.state.orderItems.map((item, key) => (
              <View key={key} style={{ marginLeft: 20, marginRight: 20 }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={[
                      styles.itemTitle,
                      { color: "#63a8a2", width: quantityWidth },
                    ]}
                  >
                    x{item.quantity}
                  </Text>
                  <Text style={[styles.itemTitle, { width: titleWidth }]}>
                    {item.name}
                  </Text>
                  <Text
                    style={[
                      styles.itemTitle,
                      { textAlign: "right", width: priceWidth },
                    ]}
                  >
                    RM{item.price}
                  </Text>
                </View>
                {item.orderItemAddOns ? (
                  item.orderItemAddOns.map((option, k) => (
                    <View
                      key={k}
                      style={{
                        flexDirection: "row",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <Text style={{ fontSize: 12, marginLeft: 42 }}>
                          {option.name}
                        </Text>
                        <Text style={{ marginRight: 5, fontSize: 12 }}>:</Text>
                        <Text style={{ fontSize: 12 }}>{option.choice}</Text>
                      </View>
                      <Text style={{ textAlign: "right", fontSize: 12 }}>
                        RM{option.price}
                      </Text>
                    </View>
                  ))
                ) : (
                  <View />
                )}
                {item.extra.length > 0 && (
                  <View
                    style={{
                      flexDirection: "column",
                      backgroundColor: "#F1F2F2",
                      padding: 4,
                      marginBottom: 4,
                      marginTop: 8,
                    }}
                  >
                    <Text
                      style={{
                        color: "#636466",
                      }}
                    >
                      {item.extra}
                    </Text>
                  </View>
                )}
              </View>
            ))}
            <Divider
              style={{
                backgroundColor: "#636466",
                marginTop: 20,
                marginBottom: 20,
                width: "90%",
                alignSelf: "center",
              }}
            />
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ color: "#636466", marginLeft: 20 }}>Subtotal</Text>
              <Text style={{ color: "#636466", marginRight: 20 }}>
                {this.state.subtotal}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ color: "#636466", marginLeft: 20, marginTop: 6 }}>
                Discount
              </Text>
              <Text style={{ color: "#636466", marginRight: 20, marginTop: 6 }}>
                {this.state.discount}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ color: "#636466", marginLeft: 20, marginTop: 6 }}>
                Tax
              </Text>
              <Text style={{ color: "#636466", marginRight: 20, marginTop: 6 }}>
                {this.state.taxes}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  color: "#636466",
                  marginLeft: 20,
                  fontWeight: "bold",
                  marginTop: 6,
                }}
              >
                Total
              </Text>
              <Text
                style={{
                  color: "#636466",
                  marginRight: 20,
                  fontWeight: "bold",
                  marginTop: 6,
                }}
              >
                {this.state.total}
              </Text>
            </View>
            <Divider
              style={{
                backgroundColor: "#636466",
                marginTop: 20,
                marginBottom: 20,
                width: "90%",
                alignSelf: "center",
              }}
            />
            <View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  icon={() => <Wallet width={15} height={15} />}
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                  color="#2A8597"
                  size={15}
                />
                <Text style={{ fontSize: 13 }}>
                  Payment Method: {paymentMethod}
                </Text>
              </View>
              {/* <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                  <IconButton
                    icon={() => (
                      <Payment width={20} height={20} />
                    )}
                    color="#2A8597"
                    size={10}
                    style={{ marginLeft: 20, marginRight: 20 }}
                  />
                  <Text style={{ fontSize: 13, width: '90%' }}>Promotion: Weekend Promo 30% off on selected items</Text>
                </View> */}
            </View>
          </View>
          <GButton
            onPress={() => {
              this.props.history.push(`/menu/${this.state.outletId}`);
            }}
            style={{ width: "100%", position: "absolute", bottom: 0 }}
            content={"Close"}
          />
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  AppBar: {
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: 18,
  },
  back: {
    position: "absolute",
    top: 0,
    left: 0,
    marginTop: 5,
    marginLeft: 5,
  },
  container2: {
    flex: 1,
    flexDirection: "column",
    height: 100,
    alignItems: "center",
  },
  container3: {
    flexDirection: "column",
  },
  logo: {
    position: "absolute",
    zIndex: 1,
    borderRadius: 100,
    borderWidth: 3,
    borderColor: "white",
  },
  header: {
    position: "absolute",
    top: 0,
    marginTop: 7,
    fontSize: 24,
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  headerWrapper: {
    alignItems: "center",
    backgroundColor: "#218096",
  },
  bottomView: {
    marginTop: 30,
    borderWidth: 1,
    borderBottomWidth: 0,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderColor: "#D8E0E1",
    backgroundColor: "#FFF",
  },
  button: {
    width: "100%",
    borderColor: "#2A8597",
    borderWidth: 2,
  },
  itemTitle: {
    marginBottom: 15,
    marginTop: 15,
    fontSize: 14,
    color: "#636466",
  },
});
