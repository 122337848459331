import React, { Component } from "react";
import {
  Text,
  Button,
  TouchableRipple,
  Appbar,
  Divider,
  IconButton,
  Paragraph,
  Portal,
  Dialog,
  TextInput,
} from "react-native-paper";
import {
  SafeAreaView,
  View,
  StyleSheet,
  ScrollView,
  Dimensions,
} from "react-native";
import { SwipeListView } from "react-native-swipe-list-view";
import AsyncStorage from "@callstack/async-storage";
import { ReactComponent as Wallet } from "../assets/wallet.svg";
import GButton from "../components/GradientButton";
import { client } from "../utils/apiClient";
import RadioButtons from "../components/RadioButtons";
import { withTheme, Snackbar } from "react-native-paper";

class CheckOutScreen extends Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      instructions: "",
      subtotal: 0,
      taxes: null,
      total: 0,
      walletBalance: "",
      paymentMethod: "cash",
      merchantName: "",
      data: [],
      checked: false,
      loading: false,
      chosenTime: new Date(),
      show: false,
      disabledbutton: true,
      showPaymentMethodDialog: false,
      snackVisible: false,
      snackMessage: "",
      tableNumber: "",
      paymentMethods: [
        { label: "Cash", value: "cash" },
        { label: "Credit card", value: "cc" },
      ],
    };

    this.ableToProceedAgain = this.ableToProceedAgain.bind(this);
    this.checkOut = this.checkOut.bind(this);
    this.confirmUpSell = this.confirmUpSell.bind(this);
    this.cancelUpSell = this.cancelUpSell.bind(this);
    this.setTime = this.setTime.bind(this);

    this.showPaymentMethodDialog = this.showPaymentMethodDialog.bind(this);
    this.hidePaymentMethodDialog = this.hidePaymentMethodDialog.bind(this);
    this.updatePaymentMethod = this.updatePaymentMethod.bind(this);

    AsyncStorage.multiGet(["paymentMethod", "qrType"]).then((res) => {
      this.setState({
        paymentMethod: res[0][1] || "cash",
        qrType: res[1][1],
      });
    });

    this.goBack = this.goBack.bind(this);
  }

  async calculateTotal() {
    let data = await AsyncStorage.getItem("cart");
    let subtotal = 0;
    let taxes = 0;
    let total = 0;

    if (data) {
      data = JSON.parse(data);
      let items = {
        items: data.items.map((o) => {
          delete o.upsell;
          return o;
        }),
      };
      try {
        const res = await client.post("/upsell", items);
        const resItems = res.data.map((obj) => {
          obj.id = obj.id.toString();
          return obj;
        });
        this.setState({ data: resItems });

        data.items.map((v, i, a) => {
          a[i].id = a[i].id.toString();
          subtotal += parseFloat(a[i].totalPrice);
        });

        const taxRes = await client.get(`/merchantTax/${data.outletId}`);
        taxes = (parseFloat(taxRes.data.taxPercent) / 100) * subtotal;
        this.setState({ taxes: taxes.toFixed(2) });
        total = subtotal + taxes;
        this.setState({
          total: total.toFixed(2),
          subtotal: subtotal.toFixed(2),
        });
      } catch (err) {
        this.goBack();
      }
    }
  }

  async btnDeleteClick(id, rowMap) {
    rowMap[id].closeRow();
    let items = this.state.data;
    items = items.filter((v, i, a) => {
      return v.id !== id;
    });
    items.map((v, i, a) => {
      a[i].id = i.toString();
    });

    let data = await AsyncStorage.getItem("cart");
    data = JSON.parse(data);
    data.items = items;
    await AsyncStorage.setItem("cart", JSON.stringify(data));
    this.setState({ data: items }, () => this.calculateTotal());
  }

  async parsePaymentStatus() {
    if (this.props.location.search.length === 0) {
      return;
    }
    const queryStr = this.props.location.search.substr(1);
    const queries = queryStr.split("&");
    const query = {};
    for (const q of queries) {
      const [key, val] = q.split("=");
      query[key] = val;
    }
    if (query["paymentStatus"]) {
      switch (query["paymentStatus"]) {
        case "1":
          if (query["orderId"]) {
            await AsyncStorage.removeItem("cart");
            this.props.history.push({
              pathname: "orderSuccess",
              state: {
                totalPrice: this.state.total,
                orderId: query["orderId"],
                paymentMethod: this.state.paymentMethod,
                paid: true,
              },
            });
          }
          break;
        case "0":
          alert("Payment failed");
          this.props.history.push({
            pathname: "/checkout",
          });
          break;
      }
    }
  }

  async componentDidMount() {
    let data = await AsyncStorage.getItem("cart");
    if (!data) {
      const outletId = await AsyncStorage.getItem("outletId");
      this.props.history.push(`/menu/${outletId}`);
      return;
    }
    this.parsePaymentStatus();
    this.calculateTotal();
  }

  goBack() {
    this.props.history.goBack();
  }

  ableToProceedAgain() {
    this.setState({ loading: false });
  }

  async checkOut() {
    this.setState({ loading: true });
    let type = await AsyncStorage.getItem("orderType");
    let cart = await AsyncStorage.getItem("cart");
    cart = JSON.parse(cart);
    cart.paymentMethod = this.state.paymentMethod;
    cart.customTable = this.state.tableNumber;
    await AsyncStorage.setItem("cart", JSON.stringify(cart));

    if (type === "self-order") {
      cart.type = type;

      try {
        const submitRes = await client.post("/order2", cart);
        if (this.state.paymentMethod == "cash") {
          this.props.history.push({
            pathname: "orderSuccess",
            state: {
              totalPrice: this.state.total,
              orderId: submitRes.data.id,
              paymentMethod: this.state.paymentMethod,
            },
          });
        } else if (this.state.paymentMethod === "cc") {
          const { data: data2 } = await client.post("/invoice", {
            orderId: submitRes.data.id,
            paymentMethod: this.state.paymentMethod,
          });

          this.setState({
            loading: false,
          });

          window.location.href = data2.url;
        }
      } catch (err) {
        this.setState({
          snackVisible: true,
          snackMessage:
            "Unable to process your order, please get assistance from waiter",
        });
      }
    }
  }

  async confirmUpSell(item) {
    let data = JSON.parse(await AsyncStorage.getItem("cart"));
    item.id = item.id.toString();
    for (const i of data.items) {
      if (i.id === item.id) {
        i.totalPrice = Number(i.totalPrice) + Number(item.price);
        const choiceOption = i.options.find((o) => o.option === item.option);
        choiceOption.choice = item.choice;
      }
    }
    await AsyncStorage.setItem("cart", JSON.stringify(data));
    this.calculateTotal();
  }

  async cancelUpSell(item) {
    let data = await AsyncStorage.getItem("cart");
    item.id = item.id.toString();
    data = JSON.parse(data);
    for (const i of data.items) {
      if (i.id === item.id) {
        i.upsellCancel = true;
      }
    }
    await AsyncStorage.setItem("cart", JSON.stringify(data));
    this.calculateTotal();
  }

  setTime(event, newTime) {
    if (newTime) this.setState({ chosenTime: newTime, show: false });
  }

  showPaymentMethodDialog() {
    this.setState({ showPaymentMethodDialog: true });
  }
  hidePaymentMethodDialog() {
    this.setState({ showPaymentMethodDialog: false });
  }

  translatePaymentMethod(rawString) {
    let method = this.state.paymentMethods.find((o) => o.value === rawString);
    return method ? method.label : "";
  }

  updatePaymentMethod(method) {
    this.setState({ paymentMethod: method });
    AsyncStorage.setItem("paymentMethod", method);
    this.hidePaymentMethodDialog();
  }

  render() {
    const windowHeight = Dimensions.get("window").height;
    let windowWidth = Dimensions.get("window").width;
    let beverageWidth = Dimensions.get("window").width * 0.5;
    let quantityWidth = Dimensions.get("window").width * 0.1;
    let titleWidth = Dimensions.get("window").width * 0.6;
    let priceWidth = Dimensions.get("window").width * 0.2;
    let choiceTitleWidth = Dimensions.get("window").width * 0.4;
    let choiceTextWidth = Dimensions.get("window").width * 0.2;
    const paymentMethod = this.translatePaymentMethod(this.state.paymentMethod);

    return (
      <SafeAreaView style={{ flex: 1, height: windowHeight }}>
        <Appbar.Header style={{ backgroundColor: "#FFFFFF", elevation: 0 }}>
          <Appbar.BackAction onPress={this.goBack} />
          <Appbar.Content
            style={{ alignItems: "center" }}
            titleStyle={styles.AppBar}
            title="CART"
          />
          <Appbar.Action />
        </Appbar.Header>

        <View style={styles.topView}>
          <Text
            style={{
              width: beverageWidth,
              fontSize: 18,
              fontWeight: "bold",
              color: "#636466",
            }}
          >
            {this.state.merchantName}
          </Text>
        </View>

        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            marginBottom: 60,
            backgroundColor: "#f4f3e9",
          }}
        >
          <View style={{ backgroundColor: "#f4f3e9", height: 10 }} />
          <SwipeListView
            data={this.state.data}
            renderItem={({ item }, rowMap) => (
              <View style={styles.rowFront}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={[
                      styles.itemTitle,
                      { color: "#63a8a2", width: quantityWidth },
                    ]}
                  >
                    x{item.quantity}
                  </Text>
                  <View style={{ flexDirection: "column" }}>
                    <Text style={[styles.itemTitle, { width: titleWidth }]}>
                      {item.title}
                    </Text>
                    <View style={{ flexDirection: "column" }}>
                      {item.options ? (
                        item.options.map((option, k) => (
                          <View key={k} style={styles.information}>
                            <Text
                              style={{
                                width: choiceTitleWidth,
                                color: "#000000",
                              }}
                            >
                              {option.option}
                            </Text>
                            <Text style={{ marginRight: 5 }}>:</Text>
                            <Text
                              style={{
                                width: choiceTextWidth,
                                color: "#000000",
                              }}
                            >
                              {option.choice}
                            </Text>
                          </View>
                        ))
                      ) : (
                        <View />
                      )}
                    </View>
                    {item.remark.length > 0 && (
                      <View
                        style={{
                          marginTop: 8,
                          flexDirection: "column",
                          backgroundColor: "#F1F2F2",
                          padding: 4,
                          width: "100%",
                        }}
                      >
                        <Text
                          style={{
                            color: "#636466",
                          }}
                        >
                          {item.remark}
                        </Text>
                      </View>
                    )}
                  </View>

                  <Text style={[styles.itemTitle, { width: priceWidth }]}>
                    {item.totalPrice}
                  </Text>
                </View>

                <Button
                  onPress={() => {
                    this.props.history.push({
                      pathname: "editOrderItem",
                      state: {
                        id: item.id,
                        itemId: item.itemId,
                      },
                    });
                  }}
                  style={{
                    //color: "#63a8a2",
                    marginTop: 10,
                    marginBottom: 5,
                    width: "25%",
                    //fontSize: 8,
                  }}
                >
                  EDIT
                </Button>

                {item.upsell && (
                  <View key={item.id.toString()}>
                    <Text style={{ marginLeft: 45, color: "#ff0000" }}>
                      {item.upsell.name} (+{item.upsell.price})
                    </Text>
                    <Paragraph style={{ marginLeft: 45 }}>
                      {item.upsell.description}
                    </Paragraph>
                    <View style={{ flexDirection: "row" }}>
                      <Button
                        style={{ marginLeft: 18 }}
                        onPress={() => this.confirmUpSell(item.upsell)}
                      >
                        Yes
                      </Button>
                      <Button onPress={() => this.cancelUpSell(item.upsell)}>
                        No
                      </Button>
                    </View>
                  </View>
                )}
              </View>
            )}
            renderHiddenItem={(data, rowMap) => {
              return (
                <View style={styles.rowBack}>
                  <TouchableRipple
                    centered
                    style={[styles.deleteStyle, { width: "35%" }]}
                    onPress={() => this.btnDeleteClick(data.item.id, rowMap)}
                  >
                    <Text
                      style={{
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      {" "}
                      Delete
                    </Text>
                  </TouchableRipple>
                </View>
              );
            }}
            recalculateHiddenLayout={true}
            keyExtractor={(item) => item.id}
            disableRightSwipe
            rightOpenValue={-(windowWidth / 3)}
          />

          <View style={{ backgroundColor: "#f4f3e9", paddingTop: 10 }}>
            <View style={styles.bottomView}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    color: "#636466",
                    marginLeft: 20,
                    marginTop: 25,
                    fontWeight: "bold",
                  }}
                >
                  Orders
                </Text>
                <Text
                  style={{
                    color: "#636466",
                    marginRight: 20,
                    marginTop: 25,
                    fontWeight: "bold",
                  }}
                >
                  Items
                </Text>
              </View>
              <Divider
                style={{
                  backgroundColor: "#636466",
                  marginTop: 20,
                  marginBottom: 20,
                  width: "90%",
                  alignSelf: "center",
                }}
              />
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ color: "#636466", marginLeft: 20 }}>
                  Subtotal
                </Text>
                <Text style={{ color: "#636466", marginRight: 20 }}>
                  {this.state.subtotal}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{ color: "#636466", marginLeft: 20, marginTop: 6 }}
                >
                  Tax
                </Text>
                <Text
                  style={{
                    color: "#636466",
                    marginRight: 20,
                    marginTop: 6,
                  }}
                >
                  {this.state.taxes}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    color: "#636466",
                    marginLeft: 20,
                    fontWeight: "bold",
                    marginTop: 6,
                  }}
                >
                  Total
                </Text>
                <Text
                  style={{
                    color: "#636466",
                    marginRight: 20,
                    fontWeight: "bold",
                    marginTop: 6,
                  }}
                >
                  {this.state.total}
                </Text>
              </View>
              <View style={styles.walletButton}>
                <TouchableRipple onPress={this.showPaymentMethodDialog}>
                  <View style={{ flexDirection: "row" }}>
                    <IconButton
                      color="#63a8a2"
                      icon={() => <Wallet width={20} height={20} />}
                      size={25}
                      style={{ marginLeft: 10 }}
                    />
                    <Text
                      style={{
                        alignSelf: "center",
                        color: "#636466",
                      }}
                    >
                      {paymentMethod}
                    </Text>
                  </View>
                </TouchableRipple>
                {/* <TouchableRipple
                  onPress={() => {
                    Alert.alert("You do not have any promotion available.");
                  }}
                >
                  <Text
                    style={{
                      alignSelf: "center",
                      marginRight: 20,
                      color: "#636466"
                    }}
                  >
                    Add Promo or Reward
                  </Text>
                </TouchableRipple> */}
              </View>
              {this.state.qrType === "entrance" && (
                <View style={{ paddingHorizontal: 20, marginBottom: 20 }}>
                  <TextInput
                    mode="outlined"
                    label="Table Number"
                    dense={true}
                    value={this.state.tableNumber}
                    onChangeText={(val) => this.setState({ tableNumber: val })}
                  />
                </View>
              )}
            </View>
          </View>
        </ScrollView>
        <GButton
          onPress={() => {
            if (this.state.total === 0) {
              alert("The cart is empty, please add items to proceed.");
            } else {
              this.checkOut();
            }
          }}
          style={{ width: "100%", position: "absolute", bottom: 0 }}
          loading={this.state.loading}
          content={"Proceed to payment : RM" + this.state.total}
        />
        <Portal>
          <Dialog
            visible={this.state.showPaymentMethodDialog}
            onDismiss={this.hidePaymentMethodDialog}
          >
            <Dialog.Title>Select payment method</Dialog.Title>
            <Dialog.Content>
              <RadioButtons
                onValueChange={(value) => {
                  this.setState({ paymentMethod: value });
                  this.hidePaymentMethodDialog();
                }}
                value={this.state.paymentMethod}
                options={this.state.paymentMethods.map((obj, i) => {
                  return { key: obj.value, text: obj.label };
                })}
              />
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={this.hidePaymentMethodDialog}>Close</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <Snackbar
          visible={this.state.snackVisible}
          onDismiss={() => this.setState({ snackVisible: false })}
        >
          {this.state.snackMessage}
        </Snackbar>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  AppBar: {
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: 18,
  },
  button: {
    width: "100%",
    borderColor: "#2A8597",
    borderWidth: 2,
  },
  back: {
    position: "relative",
    top: 0,
    left: 0,
    // marginTop: 5,
    marginLeft: 10,
  },
  topView: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 25,
  },
  headerWrapper: {
    height: 50,
  },
  rowFront: {
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    borderBottomColor: "black",
    borderTopWidth: 1,
  },
  rowBack: {
    backgroundColor: "#d11a2a",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  deleteStyle: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  bottomView: {
    borderWidth: 1,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderColor: "#D8E0E1",
    backgroundColor: "#FFF",
  },
  information: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  itemTitle: {
    marginBottom: 15,
    marginTop: 20,
    fontWeight: "bold",
    fontSize: 15,
    color: "#636466",
  },
  walletButton: {
    alignSelf: "center",
    borderWidth: 1,
    borderColor: "#dfdfc1",
    borderRadius: 6,
    width: "85%",
    marginTop: 20,
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    height: 40,
    alignItems: "center",
  },
  pickercontainer: {
    marginTop: 20,
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 10,
  },
});

export default withTheme(CheckOutScreen);
