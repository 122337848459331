import React, { Fragment } from "react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { DefaultTheme, Provider as PaperProvider } from "react-native-paper";
import { Platform } from "react-native";
import { AppRegistry } from "react-native";
import { BrowserRouter } from "react-router-dom";

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: "#47B3B0",
    accent: "#2996A3",
    titleFilled: "#FFF",
    linearStart: "#3E9AAC",
    linearEnd: "#4D7F94",
    buttonLinearStart: "#6DC497",
    buttonLinearEnd: "#218096",
    delLinearStart: "#F3723E",
    delLinearEnd: "#b80015",
    text: "#58595B",
    disabled: "#C7C8CA",
    placeholder: "#C7C8CA",
    input: "#939598"
  }
};

function Main() {
  return (
    <BrowserRouter basename={'/tableorder'}>
      <PaperProvider theme={theme}>
        <Fragment>
          {Platform.OS === "web" ? (
            <style type="text/css">{`
              @font-face {
                font-family: 'MaterialCommunityIcons';
                src: url(${require("react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf")}) format('truetype');
              }
            `}</style>
          ) : null}
          <App />
        </Fragment>
      </PaperProvider>
    </BrowserRouter>
  );
}

const appName = "TableOrder";
AppRegistry.registerComponent(appName, () => Main);
AppRegistry.runApplication(appName, {
  rootTag: document.getElementById("root")
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
