import axios from "axios";
import { apiUrl } from "../config/url";
import AsyncStorage from "@callstack/async-storage";

export const client = axios.create({
  baseURL: apiUrl,
});

client.interceptors.request.use(async (config) => {
  const token = await AsyncStorage.getItem("token");
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});
