import React, { Component } from "react";
import { Text, View, StyleSheet } from "react-native";
import { withTheme, TouchableRipple } from "react-native-paper";

class RadioButtons extends Component {
  state = {
    value: this.props.value
  };

  render() {
	const { options, onValueChange, style } = this.props;
    const { value } = this.state;
    return (
      <View>
        {options.map(item => {
          return (
            <TouchableRipple
              key={item.key}
              style={[style, { marginBottom: 10, paddingTop:5, paddingBottom: 5 }]}
              onPress={() => {
                this.setState({
                  value: item.key
                });
                if (onValueChange) {
                  onValueChange(item.key);
                }
              }}
            >
              <View style={styles.buttonContainer}>
                <Text>{item.text}</Text>
                <View
                  style={[
                    styles.circle,
                    { borderColor: this.props.theme.colors.input }
                  ]}
                >
                  {value === item.key && (
                    <View
                      style={[
                        styles.checkedCircle,
                        { backgroundColor: this.props.theme.colors.primary }
                      ]}
                    />
                  )}
                </View>
              </View>
            </TouchableRipple>
          );
        })}
      </View>
    );
  }
}

export default withTheme(RadioButtons);

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },

  circle: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center"
  },

  checkedCircle: {
    width: 14,
    height: 14,
    borderRadius: 7
  }
});
