import React, { Component, Fragment } from "react";
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  ScrollView,
  SafeAreaView,
  Dimensions,
} from "react-native";
import {
  Appbar,
  Avatar,
  Divider,
  TouchableRipple,
  Portal,
  withTheme,
} from "react-native-paper";
import AsyncStorage from "@callstack/async-storage";
import { client } from "../utils/apiClient";
import GButton from "../components/GradientButton";

function Category({ name }) {
  return (
    <View style={styles.category}>
      <Text style={styles.name}>{name}</Text>
    </View>
  );
}

function FoodDesc({ title, desc, price, uri, logosize, code }) {
  var descComponents = [];
  if (code.length > 0) {
    descComponents.push(code);
  }
  if (desc.length > 0) {
    descComponents.push(desc);
  }
  desc = descComponents.join(" | ");
  return (
    <View style={{ flex: 1, flexDirection: "column" }}>
      <View style={styles.foodDesc}>
        <Avatar.Image
          style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}
          size={logosize}
          source={{ uri: uri }}
        />
        <View style={{ flex: 1, flexDirection: "column", marginTop: 20 }}>
          <Text style={{ fontSize: 15, fontWeight: "bold" }}>{title}</Text>
          <Text style={{ fontSize: 13, color: "#696969", marginTop: 10 }}>
            {desc}
          </Text>
        </View>
        <Text
          style={{
            fontSize: 15,
            fontWeight: "bold",
            marginTop: 20,
            marginRight: 10,
          }}
        >
          {price}
        </Text>
      </View>
      <Divider style={{ marginTop: 20, height: 2 }} />
    </View>
  );
}

class FoodMenuScreen extends Component {
  static navigationOptions = {
    header: null,
  };
  constructor(props) {
    super(props);
    this.state = {
      menu: [],
      subs: [],
      catename: "",
      count: 0,
      price: 0,
      disablebutton: true,
      orderType: "",
      merchantLogo: "",
      showDownload: "",
    };
    this.renderCheckOutCart = this.renderCheckOutCart.bind(this);
  }

  _onPress = (item) => {
    let subs = this.state.menu.filter((i) => {
      return i.category === item;
    });
    this.setState({ subs: subs[0].items });
    this.setState({ catename: item });
  };

  async renderCheckOutCart() {
    let cartItems = await AsyncStorage.getItem("cart");
    if (cartItems) {
      cartItems = JSON.parse(cartItems);
      if (cartItems.items) {
        this.setState({ count: cartItems.items.length });

        let totalPrice = 0;
        cartItems.items.map((v, i, a) => {
          totalPrice += parseFloat(v.totalPrice);
          return null;
        });

        this.setState({ price: totalPrice.toFixed(2) });
      }
    }

    if (this.state.count < 1) {
      this.setState({ disablebutton: true });
    } else {
      this.setState({ disablebutton: false });
    }
  }

  async componentDidMount() {
    let { outletId } = this.props.match.params;
    if (!outletId) {
      this.props.history.push("/");
      return;
    }

    const cache = await AsyncStorage.multiGet([
      "orderType",
      "outletInfo",
      "showDownload",
    ]);
    const orderType = cache[0][1];
    const showDownload = cache[2][1];

    this.setState({ orderType, showDownload });

    let outletJson = cache[1][1];

    let logo = "";
    if (outletJson) {
      let outlet = JSON.parse(outletJson);
      if (outlet) {
        logo = outlet.logo;
      }
    } else {
      // Load outlet data
      const outletRes = await client.get(`/merchant/${outletId}`);
      if (outletRes) {
        await AsyncStorage.setItem(
          "outletInfo",
          JSON.stringify(outletRes.data)
        );
        logo = outletRes.data.logo;
      }
    }

    this.setState({ merchantLogo: logo });

    // Fetch menu
    try{
      const menuRes = await client.get(`/merchantMenu/${outletId}`);
      const menu = menuRes.data;
      if (menu.length === 0) {
        return;
      }
      const subs = menu[0].items;
      const catename = subs[0].category;
      this.setState({ menu, subs, catename });
      let cartItems = await AsyncStorage.getItem("cart");
      if (cartItems) {
        cartItems = JSON.parse(cartItems);
        if (cartItems.items) {
          this.setState({ count: cartItems.items.length });
  
          let totalPrice = 0;
          cartItems.items.map((v, i, a) => {
            totalPrice += parseFloat(v.totalPrice);
            return null;
          });
  
          this.setState({ price: totalPrice.toFixed(2) });
        }
      } else {
        this.setState({ count: 0 });
      }
  
      if (this.state.count < 1) {
        this.setState({ disablebutton: true });
      } else {
        this.setState({ disablebutton: false });
      }
    }catch(err){
      await AsyncStorage.removeItem("token");
      this.props.history.goBack();
    }
  }

  render() {
    const colors = this.props.theme.colors;
    const logoSize = 80;
    const windowHeight = Dimensions.get("window").height;
    return (
      <Fragment>
        <SafeAreaView style={{ backgroundColor: colors.accent }} />
        <SafeAreaView
          style={{ flex: 1, flexDirection: "column", height: windowHeight }}
        >
          <Appbar.Header
            style={{ backgroundColor: colors.accent, elevation: 0 }}
          >
            <Appbar.Action />
            <Appbar.Content
              color="#FFFFFF"
              style={{ alignItems: "center" }}
              titleStyle={styles.AppBar}
              title="Menu"
            />
            <Appbar.Action />
          </Appbar.Header>
          <View
            style={{
              alignItems: "center",
              height: 150,
              backgroundColor: "#97cae0",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <View
              style={{
                backgroundColor: colors.accent,
                height: 50,
                width: "100%",
              }}
            />
            <View style={[styles.logo, { top: 0 }]}>
              <Avatar.Image
                size={logoSize}
                source={{
                  uri: this.state.merchantLogo,
                }}
              />
            </View>
            <View style={{ marginTop: 50, width: "100%" }}>
              <FlatList
                data={this.state.menu}
                horizontal={true}
                renderItem={({ item }) => (
                  <TouchableRipple
                    underlayColor={"#5f9ea0"}
                    onPress={this._onPress.bind(this, item.category)}
                    style={{ alignItems: "center" }}
                  >
                    <Category name={item.category} />
                  </TouchableRipple>
                )}
                keyExtractor={(item) => item.category}
              />
            </View>
          </View>

          <ScrollView
            contentContainerStyle={{
              backgroundColor: colors.background,
              flexGrow: 1,
            }}
          >
            <View style={{ marginLeft: 20, marginTop: 10 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  color: colors.accent,
                }}
              >
                {this.state.catename}
              </Text>
            </View>
            <View style={{ paddingBottom: 66 }}>
              <FlatList
                data={this.state.subs}
                renderItem={({ item }) => (
                  <TouchableRipple
                    disabled={this.state.orderType === ""}
                    onPress={() => {
                      this.props.history.push({
                        pathname: `${this.props.match.url}/item`,
                        state: {
                          itemId: item.id,
                        },
                      });
                    }}
                    rippleColor="rgba(0, 0, 0, .32)"
                  >
                    <FoodDesc
                      title={item.name}
                      logosize={logoSize}
                      desc={item.description}
                      price={item.price}
                      code={item.code}
                      uri={
                        item.image
                          ? item.image
                          : "https://image.flaticon.com/icons/png/512/45/45332.png"
                      }
                    />
                  </TouchableRipple>
                )}
                keyExtractor={(item) => item.id.toString()}
              />
            </View>
          </ScrollView>
          {this.state.orderType !== "" && (
            <Portal>
              <GButton
                onPress={() => {
                  this.props.history.push("/checkout");
                }}
                style={{
                  width: "100%",
                  position: "absolute",
                  bottom: 0,
                  zIndex: 100,
                }}
                content={
                  "Checkout Cart " +
                  "(" +
                  this.state.count +
                  ")" +
                  ": RM" +
                  this.state.price
                }
                disabled={this.state.disablebutton}
              />
            </Portal>
          )}
        </SafeAreaView>
      </Fragment>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  AppBar: {
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: 18,
  },
  logo: {
    position: "absolute",
    zIndex: 100,
    borderRadius: 100,
    borderWidth: 3,
    borderColor: "white",
    top: 0,
  },
  header: {
    position: "absolute",
    top: 0,
    marginTop: 7,
    fontSize: 24,
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  back: {
    position: "absolute",
    top: 0,
    left: 0,
    marginTop: 5,
    marginLeft: 5,
  },
  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  category: {
    paddingLeft: 20,
    paddingRight: 20,
    height: 40,
    flexDirection: "row",
    alignItems: "center",
  },
  name: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center",
  },
  foodDesc: {
    flex: 1,
    flexDirection: "row",
  },
});

export default withTheme(FoodMenuScreen);
