import React, { useRef, useState } from "react";
import {
  Alert,
  StyleSheet,
  Text,
  TouchableHighlight,
  View,
  Image,
  Animated,
  Platform,
} from "react-native";
import { withTheme, Button, IconButton } from "react-native-paper";
import AsyncStorage from "@callstack/async-storage";
import GiftCardItem from "./GiftCardItem";

const PSDownload = (props) => {
  const topPos = useRef(new Animated.Value(-350)).current;
  const downloadApp = () => {
    slideOut();
    window.location.href='http://onelink.to/jmyy75';
    // if (navigator.userAgent.indexOf('iPhone') > -1) {
    //   window.location.href =
    //   "https://apps.apple.com/us/app/perksense/id842356201";
    // } else {
    //   window.location.href =
    //   "https://play.google.com/store/apps/details?id=com.perksense.and";
    // }
  };
  const slideIn = () => {
    Animated.timing(topPos, {
      toValue: 0,
      duration: 1000,
    }).start();
  };

  slideIn();

  const slideOut = () => {
    Animated.timing(topPos, {
      toValue: -350,
      duration: 500,
    }).start();
    AsyncStorage.removeItem("showDownload");
  };

  return (
    <Animated.View
      style={[
        {
          width: "100%",
          alignItems: "center",
        },
        {
          top: topPos,
        },
      ]}
    >
      <View
        style={{
          width: "90%",
          paddingHorizontal: 10,
          paddingVertical: 20,
          backgroundColor: "#fff",
          borderRadius: 10,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 10,
          },
          shadowOpacity: 0.51,
          shadowRadius: 13.16,
          elevation: 20,
          margin: "5%",
          alignItems: "center",
        }}
      >
        <IconButton
          icon="close"
          onPress={() => {
            slideOut();
          }}
          size={20}
          style={{
            position: "absolute",
            top: -10,
            right: -10,
            zIndex: 100,
          }}
        />
        <GiftCardItem
          style={{
            height: 100,
            marginBottom: 10,
            width: "100%",
          }}
          image={props.ctaInfo.logo}
          title={props.ctaInfo.title}
        />
        <View
          style={{
            marginVertical: 10,
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
            }}
          >
            You have an e-Gift in PerkSense app!
          </Text>
        </View>

        <View
          style={{
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              width: 200,
              height: 40,
            }}
            dark={true}
            mode="contained"
            onPress={() => downloadApp()}
          >
            Download Now
          </Button>
        </View>
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    width: 400,
    height: 400,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
});

export default withTheme(PSDownload);
